import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
// react components for routing our app without refresh
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
// core components

import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"

import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
import SEO from "../components/SEO";
import Social from "../components/Social.jsx";

import './temp.scss';

function Template({
  data,
  classes,
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const url = data.site.siteMetadata.url;
  const twitterHandle = data.site.siteMetadata.twitterUsername

  return (
    <div>
    <SEO image={require(`assets/img/${frontmatter.img}`)} title={frontmatter.title}/>
    <Header
      brand="Planwell"
      rightLinks={<HeaderLinks />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 400,
        color: "white",
      }}
    />
    <Parallax image={require(`assets/img/${frontmatter.img}`)}>
      <div className={classes.container}>
        <GridContainer />
      </div>
    </Parallax>

    <div className={classNames(classes.main, classes.mainRaised)}>
    <GridContainer
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <GridItem sm={6}>
      <div
        className={classes.center}
        style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <h1 className="subtitle" style={{color: "#D74E09"}}>{frontmatter.title}</h1>
        <h4>{frontmatter.subtitle}</h4>
        <h4>{frontmatter.date}{'  '}{frontmatter.author}</h4>
      </div>
      </GridItem>
      </GridContainer>
      <br />
      <GridContainer
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <GridItem sm={6}>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        </GridItem>
      </GridContainer>
      <GridContainer
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <GridItem sm={6}>
      <img 
      style={{width: '100%'}}
      className={
        classes.imgRaised +
        " " +
        classes.imgRounded +
        " " +
        classes.imgFluid
      }
      src={require(`assets/img/${frontmatter.secondImg}`)}
      alt=""/>
        </GridItem>
      </GridContainer>
      <GridContainer
        container
        direction="row"
        justify="center"
        alignItems="center">
      <GridItem sm={6}>
      <h2>Share this on social media</h2>
        <Social 
          socialConfig={{
            twitterHandle,
            config: {
              url: `${url}${frontmatter.path}`,
              title: frontmatter.title,
            },
          }}/>
          </GridItem>
      </GridContainer>
      <br/>
      <br/>
    </div>
    <Footer />
  </div>

  )
}

export default withStyles(componentsStyle)(Template)

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        url
        twitterUsername
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        img
        subtitle
        secondImg
      }
    }
  }
`